body {
  background-color: #e6ecf0;
}

/* Nav */

.navbar {
  margin-bottom: 1rem;
}

.navbar Link {
  font-weight: bold;
  color: white;
}

.navbar Link:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.alert-success{
  padding:5px;
  margin:5px;
}

.alert-danger{
  text-align: center;
  align-items: center;
  justify-content: center;
  padding:1px;
  margin:1px;
}

.navbar-link {
  font-weight: bold;
  color: white;
}

.navbar-link:hover {
  color: white;
  cursor: pointer;
}

.navbar-brand > img {
  display: inline-block;
  width: 20px;
  margin-bottom: 2px;
  margin-right: 5px;
  border-radius: 2px;
}

ul.nav.navbar-nav li {
  margin: 0 0.5rem;
}

.onboarding{
  margin:0;
  left: 0;
  right:0;
  top:0;
}

.onboarding > .navbar {
  background-image: url("./images/nav-bg.png");
  background-size: 100% 100%;
}

.container{
  left: 0;
  top:0;
  max-width: 5000px;
}

.map-container {
  /* Set a fixed height for the map container */
  height: 80vh;
  width: 70vw;
  left: 0;
  
  /* Add margin to push it to the right side of the page */
  /* margin-top: 10px; */
  
  /* Add some padding and background color for styling */
  padding: 20px;
  background-color: #f5f5f5;
  
  /* Add a border for visual separation */
  border: 1px solid #ccc;
  /* Add some box shadow for a subtle effect */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.route-answer {
   margin-top: 50px;
   max-width: 400px;

}

.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #fff;
padding: 2px 4px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 2px;
max-width: 420px;
min-width: 380px;
font-size: 0.8rem;
border-radius: 4px;
margin-top: 128px;
margin-left: 25px;
}

.main-header {
  text-align: center;
}

.sub-header {
  top: 0;
}

.home-hero {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
  background-image: url("./images/warehouse-pic.jpeg");
  text-align: center;
  padding: 1rem;
  background-position: center center;
  background-size: cover;
  color: #fff;
  text-shadow: 0 0 8px #66757f;
}

.home-hero2 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50;
  left: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: -1;
  text-align: center;
  padding: 5rem;
  background-position: center center;
  background-size: cover;
  color: #fff;
  text-shadow: 0 0 8px #66757f;
}

#messages .list-group-item {
  display: flex;
  align-items: flex-start;
  padding: 7px 20px 12px;
}

#messages:not(.no-hover) .list-group-item:hover {
  background-color: #e6ecf0;
}

.timeline-image {
  border-radius: 50px;
  height: 48px;
  width: 48px;
  margin: 2px 30px 10px 10px;
}

.btn:hover {
  cursor: pointer;
}

a.btn.btn-danger,
a.btn.btn-danger:hover {
  color: white;
}

.navbar-header {
  margin: 0 auto;
}


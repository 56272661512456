.file-upload-container {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-upload-container.drag-over {
  border-color: #007bff;
}

.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload-content img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

